import { Component, OnInit, ViewChild, AfterViewInit, TemplateRef } from '@angular/core';
import { Order, FilterParameters, OrderList } from '../../../interfaces/order';
import { OrdersService } from '../../../services/orders.service';
import { constants } from '../../../../assets/constants';
import { PageEvent, MatPaginator } from '@angular/material/paginator';
import { LoadingService } from '../../../services/loading.service';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { GlobalService } from '../../../services/global.service';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../../../services/auth.service';
import { TutorialService } from '../../../services/tutorial.service';
import { ConfigService } from '../../../services/config.service';

@Component({
  selector: 'app-order-list',
  templateUrl: './order-list.component.html',
  styleUrls: ['./order-list.component.scss']
})
export class OrderListComponent implements OnInit, AfterViewInit {

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('orderDialog', { static: true }) orderDialog: TemplateRef<any>;
  @ViewChild('orderList', { static: true }) orderList;

  public orders: OrderList;
  public filterParams: FilterParameters;
  public orderStatus;
  public orderBy;
  public orderDirection;
  public orderCompanies;
  public salesTakers;

  private firstLoad: boolean;

  displayedColumns = ['date', 'name', 'customer', 'status', 'price'];
  dataSource = new MatTableDataSource;

  // MatPaginator Inputs
  public pageLength = 100;
  public pageSize = 10; // default
  public pageSizeOptions: number[] = [5, 10, 25, 50, 100];
  // MatPaginator Output
  public pageEvent: PageEvent;

  setPageSizeOptions(setPageSizeOptionsInput: string) {
    this.pageSizeOptions = setPageSizeOptionsInput.split(',').map(str => +str);
  }

  constructor(private ordersService: OrdersService,
    private route: ActivatedRoute,
    private loadingService: LoadingService,
    private globalService: GlobalService,
    private translate: TranslateService,
    public dialog: MatDialog,
    public authService: AuthService,
    private tutorialService: TutorialService,
    private configService: ConfigService
  ) {
    this.filterParams = {
      status: 0,
      orderBy: 1,
      order: 2,
      quantity: 10,
      page: 1,
      company: '',
      workerSalesTaker: '',
      viewMode: 'cards'
    };
    this.orderStatus = constants.orderStatus;
    this.orderBy = constants.orderBy;
    this.orderDirection = constants.orderDirection;
    this.orderCompanies = constants.orderCompanies;

  }

  ngOnInit() {
    // this.loadConfig();
    // this.getOrders();
    //this.saveConfig();

    this.translate.get('ORDERS.ORDER_LIST').subscribe((translation) => {
      this.globalService.changePageTitle([translation]);
    });

    this.salesTakers = this.route.snapshot.data.salesTakers;

    this.firstLoad = true;
  }

  ngAfterViewInit() {
    this.loadConfig();
    this.getOrders();
  }

  private getOrders() {
    this.loadingService.showSpinner();
    const sendParams = JSON.parse(JSON.stringify(this.filterParams));

    if (Number(sendParams.status) === 0) {
      delete sendParams.status;
    }
    if (Number(sendParams.status) === -1) {
      sendParams.status = 0;
    }
    delete (sendParams.viewMode);

    // Eliminamos parametros no enviables por permisos
    if (!this.authService.checkRole(['cj_sales_order_advanced_filter'])) {
      delete (sendParams.workerSalesTaker);
      delete (sendParams.company);
    }

    this.ordersService.getOrders(sendParams).subscribe(
      result => {
        this.orders = result.orders;
        this.pageLength = result.total;
        this.loadingService.hideSpinner();

        this.dataSource = new MatTableDataSource(result.orders);

        // Si es primera carga, llamamos a tutorial
        if (this.firstLoad) {
          this.tutorialService.modalTutorial(
            {
              tutoId: 'order-welcome',
              title: 'TUTORIAL.ORDERS_WELCOME_TITLE',
              icon: 'assignment_turned_in',
              content: 'TUTORIAL.ORDERS_WELCOME_CONTENT'
            });
          this.firstLoad = false;
        }
        this.scrollToTop();
      },
      error => {
        console.log('Error');
      }
    );
  }

  public applyFilters() {
    // Modificacion de filtro: si se selecciona orderBy: 4, entonces order = 1. En caso contrario, order = 2
    if (this.filterParams.orderBy === 4) {
      this.filterParams.order = 1;
    } else {
      this.filterParams.order = 2;
    }

    this.filterParams.page = this.pageEvent ? this.pageEvent.pageIndex + 1 : this.filterParams.page;
    this.filterParams.quantity = this.pageEvent ? this.pageEvent.pageSize : this.filterParams.quantity;
    this.getOrders();
    this.saveConfig();
    this.paginator.firstPage();
  }

  public clearFilters() {
    this.filterParams.status = 0;
    this.filterParams.orderBy = 1;
    this.filterParams.order = 2;
    this.filterParams.search = '';

    this.applyFilters();
  }

  public changePage() {
    this.filterParams.page = this.pageEvent ? this.pageEvent.pageIndex + 1 : this.filterParams.page;
    this.filterParams.quantity = this.pageEvent ? this.pageEvent.pageSize : this.filterParams.quantity;
    this.getOrders();
    this.saveConfig();
  }

  private changeOrderDirection() {
    this.filterParams.order = this.filterParams.order === 1 ? 2 : 1;
    this.getOrders();
  }

  public switchViewMode(viewMode: string) {
    this.filterParams.viewMode = viewMode;
    this.saveConfig();
  }

  private statusToText(statusId: number) {
    return constants.orderStatus.find(s => s.id === statusId);
  }

  private saveConfig() {
    //localStorage.setItem('pmordersconfig', JSON.stringify(this.filterParams));
    this.configService.setItem('pmordersconfig', JSON.stringify(this.filterParams));
  }

  private loadConfig() {
    // const localParams = JSON.parse(localStorage.getItem('pmordersconfig'));
    let localParams;
    if (this.configService.getItem('pmordersconfig') !== undefined && this.configService.getItem('pmordersconfig') !== null) {
      localParams = JSON.parse(this.configService.getItem('pmordersconfig'));
      this.filterParams.company = localParams.company;
      this.filterParams.quantity = localParams.quantity;
      this.filterParams.viewMode = localParams.viewMode;
      this.filterParams.workerSalesTaker = localParams.workerSalesTaker;
    }
  }

  // Aux function for comparison of combobox objects
  public compare(val1, val2) {
    if (val1 !== undefined && val2 !== undefined && val1 !== null && val2 !== null) {
      return val1.id === val2.id;
    } else {
      return false;
    }
  }

  private openOrderModal(order: Order) {
    this.dialog.open(this.orderDialog, {
      data: order,
      minWidth: '60vw',
      maxWidth: '100vw',
      maxHeight: '100vh',
      autoFocus: false
    });
  }

  private scrollToTop() {
    this.orderList.nativeElement.scrollTop = 0;
  }


}
